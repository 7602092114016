<template>
  <div>
    <div class="flex items-center mb-5">
      <h3 class="uppercase text-lg font-semibold text-primary tracking-wide">
        Breakdown
      </h3>
      <hr class="flex-1 border-primary mx-4" />
      <is-authorized :permissions="['manage:fleets', 'update:fleets']">
        <button
          @click="addBreakdown()"
          class="
          flex
          items-center
          bg-green-200
          border
          hover:bg-green-300
          border-green-400
          hover:border-green-500
          hover:shadow
          active:shadow-inner
          transition
          duration-300
          text-secondary
          rounded-md
          px-2
          py-1
        "
        >
          <svg class="stroke-current h-5 w-5 mr-2" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" viewBox="0 0 24 24">
            <path d="M12 5v14M5 12h14" />
          </svg>
          <span>Add Breakdown</span>
        </button>
      </is-authorized>
    </div>

    <div class="flex flex-wrap">
      <vue-good-table
        v-if="breakdowns.length > 0"
        class="w-full"
        styleClass="vgt-table striped"
        mode="remote"
        :rows="breakdowns"
        :columns="columns"
        :sort-options="{
          enabled: false,
        }"
      >
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == 'is_active'">
            <span
              class="px-2 rounded-lg"
              :class="{
                'bg-yellow-200 text-yellow-700': props.row.is_active == false,
                'bg-green-200 text-green-700': props.row.is_active == true,
              }"
              >{{ props.row.is_active ? "Active" : "Expired" }}</span
            >
          </span>

          <span v-else-if="props.column.field == 'actions'">
            <is-authorized :permissions="['manage:fleets', 'delete:fleets']">
              <button
                type="button"
                @click="deleteBreakdown(props.row)"
                class="flex items-center ml-2 float-right bg-red-200 border hover:bg-red-300 border-red-400 hover:border-red-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
              >
                <svg
                  class="stroke-current h-5 w-5 mr-2"
                  fill="none"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  viewBox="0 0 24 24"
                >
                  <path d="M3 6h18M19 6v14a2 2 0 01-2 2H7a2 2 0 01-2-2V6m3 0V4a2 2 0 012-2h4a2 2 0 012 2v2M10 11v6M14 11v6" />
                </svg>
                <span></span>
              </button>
            </is-authorized>
            <is-authorized :permissions="['manage:fleets', 'update:fleets', 'read:fleets']">
              <button
                type="button"
                @click="editBreakdown(props.row)"
                class="float-right flex items-center bg-blue-200 border hover:bg-blue-300 border-blue-400 hover:border-blue-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
              >
                <svg
                  class="stroke-current h-5 w-5 mr-2"
                  fill="none"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  viewBox="0 0 24 24"
                >
                  <path d="M17 3a2.828 2.828 0 114 4L7.5 20.5 2 22l1.5-5.5L17 3z" />
                </svg>
                <span></span>
              </button>
            </is-authorized>
          </span>

          <span v-else>{{ props.formattedRow[props.column.field] }}</span>
        </template>
      </vue-good-table>
      <p class="mx-auto my-16" v-if="breakdowns.length == 0">
        There are no Breakdowns for this Fleet.
      </p>
    </div>

    <portal to="overlay-outlet">
      <panel
        :showing="addEditBreakdownPanelOpen"
        @close="addEditBreakdownPanelOpen = false"
        :title="editingBreakdown.breakdown_id ? 'Edit Breakdown' : 'Add Breakdown'"
      >
        <AddEditBreakdown :breakdown="editingBreakdown" @complete="saveBreakdown" @delete="deleteBreakdown" />
      </panel>
    </portal>

    <portal to="overlay-outlet">
      <confirm-alert :showingConfirm="confirmAlert.showing" :message="confirmAlert.message" @complete="confirmAlert.resultFunction" />
    </portal>
  </div>
</template>

<script>
const Notification = () => import("@/components/Notification.vue");
const ConfirmAlert = () => import("@/components/ConfirmAlert.vue");
import dateRangeOverlap from "@/utils/dateRangeOverlap";
const Panel = () => import("@/components/Panel.vue");
const AddEditBreakdown = () => import("@/components/Fleets/Edit/AddEditBreakdown.vue");
const IsAuthorized = () => import("@/components/Auth/IsAuthorized.vue");

export default {
  name: "Breakdown",
  components: {
    Notification,
    ConfirmAlert,
    Panel,
    AddEditBreakdown,
    IsAuthorized,
  },
  props: {
    breakdowns: Array,
  },
  data() {
    return {
      addEditBreakdownPanelOpen: false,
      editingBreakdown: {},
      confirmAlert: {
        resultFunction: "",
        showing: false,
        message: "",
        title: "",
        data: "",
      },
      columns: [
        {
          label: "Provider",
          field: "provider",
          width: "20%",
        },
        {
          label: "Policy Number",
          field: "policy_number",
          width: "20%",
        },
        {
          label: "Cover Start",
          field: "cover_start",
          // type: "date",
          formatFn: (v) => {
            return v == 0 ? "" : this.$moment(v).format("DD/MM/YYYY");
          },
          width: "20%",
        },
        {
          label: "Cover Renewal",
          field: "cover_renewal",
          // type: "date",
          formatFn: (v) => {
            return v == 0 ? "" : this.$moment(v).format("DD/MM/YYYY");
          },
          width: "20%",
        },
        {
          label: "Status",
          field: "is_active",
          width: "10%",
          tdClass: this.tblClassCenterAlign,
          thClass: this.tblClassCenterAlign,
        },
        {
          field: "actions",
          type: "custom",
          sortable: false,
          width: "10%",
        },
      ],
    };
  },
  computed: {
    fleet_id: function() {
      return this.$store.state.lastSelectedFleet;
    },
  },
  methods: {
    tblClassLeftAlign(row) {
      return "vgt-left-align";
    },
    tblClassCenterAlign(row) {
      return "vgt-center-align";
    },
    addBreakdown: function() {
      this.editingBreakdown = {};
      this.addEditBreakdownPanelOpen = true;
    },
    handleEditBreakdown: function(selectedRow) {
      let breakdownData = selectedRow.row;
      this.editBreakdown(breakdownData);
      /*this.$router.push({
              name: "AdEditBreakdown",
              params: { breakdown: breakdownData },
            });*/
    },
    editBreakdown: function(breakdown) {
      this.editingBreakdown = { ...breakdown };
      this.addEditBreakdownPanelOpen = true;
    },
    saveBreakdown: async function(breakdown) {
      // Ensure breakdown has mandatory fields filled out
      let msg;
      if (!breakdown.provider) {
        msg = "Please provide the provider name.";
      } else if (!breakdown.policy_number) {
        msg = "Please provide the policy number.";
      } else if (!breakdown.policy_type) {
        msg = "Please provide the level of cover.";
      } else if (!breakdown.cover_start) {
        msg = "Please provide policy cover start date.";
      } else if (!breakdown.cover_renewal) {
        msg = "Please provide policy cover renewal date.";
      } else if (!breakdown.reminder_date) {
        msg = "Please provide reminder date.";
      } else if (breakdown.cover_start > breakdown.cover_renewal) {
        msg = "Breakdown start date must be less than renewal date.";
      } else if (
        this.$validator.isBefore(
          breakdown.cover_renewal instanceof Date ? breakdown.cover_renewal.toDateString() : breakdown.cover_renewal,
          breakdown.cover_start instanceof Date ? breakdown.cover_start.toDateString() : breakdown.cover_start
        )
      ) {
        msg = "Cover start begin must be less than renewal date.";
      } else if (
        this.$validator.isBefore(
          breakdown.cover_renewal instanceof Date ? breakdown.cover_renewal.toDateString() : breakdown.cover_renewal,
          breakdown.reminder_date instanceof Date ? breakdown.reminder_date.toDateString() : breakdown.reminder_date
        )
      ) {
        msg = "Reminder date date must be greater than renewal date.";
      }

      this.breakdowns.forEach(function(item) {
        if (item.breakdown_id != breakdown.breakdown_id) {
          if (dateRangeOverlap(new Date(item.cover_start), new Date(item.cover_renewal), breakdown.cover_start, breakdown.cover_renewal)) {
            msg = "Date overlap with other breakdowns.";
          }
        }
      });
      if (msg) {
        return this.$breadstick.notify(
          ({ h, onClose }) => {
            return h(
              Notification,
              {
                props: {
                  type: "warning",
                  title: "Error",
                  close: onClose,
                },
              },
              msg
            );
          },
          {
            position: "top-right",
          }
        );
      }

      this.addEditBreakdownPanelOpen = false;

      try {
        breakdown.is_active = true;
        if (breakdown.cover_renewal) {
          breakdown.is_active = new Date(breakdown.cover_renewal) >= new Date();
        }
        if (!breakdown.breakdown_id) {
          let createResult = await this.FleetService.createFleetBreakdown(this.fleet_id, breakdown);
          breakdown.breakdown_id = createResult.data.breakdown_id;
          this.breakdowns.push(breakdown);
        } else {
          await this.FleetService.updateFleetBreakdown(this.fleet_id, breakdown.breakdown_id, breakdown);
          let idx = this.$_.findIndex(this.breakdowns, (c) => c.breakdown_id == breakdown.breakdown_id);
          this.breakdowns.splice(idx, 1, breakdown);
        }

        this.$emit("complete");
        this.$breadstick.notify(
          ({ h, onClose }) => {
            return h(
              Notification,
              {
                props: {
                  title: "Details Saved",
                  close: onClose,
                },
              },
              "Breakdown details saved"
            );
          },
          {
            position: "top-right",
          }
        );
      } catch (err) {
        console.error(err);

        let msg = !breakdown.breakdown_id ? "There was a problem creating the new Breakdown" : "There was a problem updating the Breakdown";

        this.$breadstick.notify(
          ({ h, onClose }) => {
            return h(
              Notification,
              {
                props: {
                  type: "danger",
                  title: "Error",
                  close: onClose,
                },
              },
              "There was a problem updating the Breakdown"
            );
          },
          {
            position: "top-right",
          }
        );
      }
    },
    handlePromoteBreakdownToCurrent: async function(result) {
      if (result) {
        this.confirmAlert.showing = false;
        try {
          this.confirmAlert.data.is_current = true;
          await this.saveBreakdown(this.confirmAlert.data);
          this.breakdowns.forEach((c) => {
            if (c.breakdown_id !== this.confirmAlert.data.breakdown_id) {
              c.is_current = false;
            }
          });
        } catch (error) {
          console.error(error);
          this.$breadstick.notify(
            ({ h, onClose }) => {
              return h(
                Notification,
                {
                  props: {
                    type: "danger",
                    title: "Error",
                    close: onClose,
                  },
                },
                "There was a problem updating the Breakdown"
              );
            },
            {
              position: "top-right",
            }
          );
        }
      }
      this.confirmAlert = {
        resultFunction: "",
        message: "",
        showing: false,
        data: "",
      };
    },
    promoteBreakdownToCurrent: function(breakdown) {
      this.confirmAlert = {
        resultFunction: this.handlePromoteBreakdownToCurrent,
        message: `Change '${breakdown.policy_number}' as current breakdown?`,
        showing: true,
        data: breakdown,
      };
    },
    handleDeleteBreakdown: async function(result) {
      if (result) {
        this.confirmAlert.showing = false;
        try {
          this.isBusy = true;
          await this.FleetService.deleteBreakdown(this.fleet_id, this.confirmAlert.data.breakdown_id);
          let idx = this.$_.findIndex(this.breakdowns, (c) => {
            return c.breakdown_id == this.confirmAlert.data.breakdown_id;
          });

          this.$delete(this.breakdowns, idx);
          this.$breadstick.notify(
            ({ h, onClose }) => {
              return h(
                Notification,
                {
                  props: {
                    title: "Breakdown Deleted",
                    close: onClose,
                  },
                },
                "Successfully deleted breakdown"
              );
            },
            {
              position: "top-right",
            }
          );
        } catch (err) {
          console.error(err);

          this.$breadstick.notify(
            ({ h, onClose }) => {
              return h(
                Notification,
                {
                  props: {
                    type: "danger",
                    title: "Error",
                    close: onClose,
                  },
                },
                "There was a problem deleting the Breakdown"
              );
            },
            {
              position: "top-right",
            }
          );
        }

        this.isBusy = false;
        this.addEditBreakdownPanelOpen = false;
      }
      this.confirmAlert = {
        resultFunction: "",
        message: "",
        showing: false,
        data: "",
      };
    },
    deleteBreakdown: function(breakdown) {
      // If we're deleting a breakdown that hasn't been saved yet
      if (String(breakdown.breakdown_id).startsWith("pending")) {
        let idx = this.$_.findIndex(this.breakdowns, (c) => {
          return c.breakdown_id == breakdown.breakdown_id;
        });

        this.$delete(this.breakdowns, idx);
        return;
      }

      this.confirmAlert = {
        resultFunction: this.handleDeleteBreakdown,
        message: `Are you sure you wish to delete '${breakdown.policy_number}' This cannot be undone.`,
        showing: true,
        data: breakdown,
      };
    },
  },
};
</script>
